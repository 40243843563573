import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      {/* Logo Section */}
      <div className="logo-container">
        <img src="/logo.png" alt="Heredity360 Logo" className="logo" />
      </div>

      {/* Navigation Section */}
      <nav className="nav-container">
        <ul className="nav-links">
          <li><a href="#home">Home</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#about">About Us</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
