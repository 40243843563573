import React from 'react';
import './Content.css';

const Content = () => {
  return (
    <main className="content">
      

      {/* Services Section */}
      <section id="services" className="services">
        {/* Column 1 */}
        <div className="service">
          <img src="/images/genetic-wellness.png" alt="Genetic Wellness" />
          <h2>Your Compass in Genetic Wellness</h2>
          <p>
            At Heredity360, we are dedicated to providing comprehensive genetic
            counseling services tailored to empower individuals and healthcare
            professionals in navigating the intricate landscape of hereditary
            cancers.
          </p>
        </div>

        {/* Column 2 */}
        <div className="service">
          <img src="/images/genetic-counseling.png" alt="Genetic Counseling" />
          <h2>Unraveling the Genetic Code for Your Well-being</h2>
          <p>
            Our expert genetic counselors specialize in guiding individuals who
            may be at risk for hereditary cancers. We offer detailed
            consultations to assess familial risk, interpret genetic test
            results, and provide personalized recommendations.
          </p>
        </div>

        {/* Column 3 */}
        <div className="service">
          <img src="/images/healthcare-support.png" alt="Healthcare Support" />
          <h2>Healthcare Professional Support</h2>
          <p>
            We collaborate with healthcare professionals to enhance their
            understanding of genetic testing and counseling. Our services
            include professional education, case consultations, and
            collaborative efforts.
          </p>
        </div>

        {/* Column 4 */}
        <div className="service">
          <img src="/images/educational-resources.png" alt="Educational Resources" />
          <h2>Educational Resources</h2>
          <p>
            Heredity360 is more than a service; it's a hub for knowledge. Explore
            articles, studies, and resources that demystify genetic concepts.
          </p>
        </div>
      </section>
      {/* Hero Section */}
      <section id="hero" className="hero">
        <h1>Genetic Counseling: Empowering Your Journey to Better Health</h1>
        <p>Your Genetic Blueprint, Decoded with Care</p>
        <div className="hero-description">
          <p>
            Understanding your genetic health can be life-changing. At <strong>Heredity360</strong>, we specialize in providing personalized genetic counseling to help you navigate hereditary risks and make informed decisions for yourself and your loved ones.
          </p>
          <p>Our expert counselors guide you in:</p>
          <ul>
            <li>Assessing your family’s genetic history.</li>
            <li>Decoding complex genetic test results.</li>
            <li>Creating proactive health strategies tailored to your unique needs.</li>
          </ul>
          <p>
            Whether you’re managing hereditary cancer risks, planning for a family, or exploring how genetics impacts your well-being, we’re here to support you with clarity and compassion. Let us help you uncover the story your DNA has to tell.
          </p>
        </div>
      </section>
    </main>
  );
};

export default Content;
